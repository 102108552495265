import { HiPencil, HiPlusCircle } from 'react-icons/hi';

import { Card, CardContent, CardHeader, CardTitle, Switch, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@amfintech/react-admin-ui';
import {
  CreateDataInput,
  createHelpers,
  createResource,
  useResourceAccess,
} from '@amfintech/refine-react-admin';

import {
  AccountType,
  ConsentType,
  ModificationEntityType,
  Profile,
  ProfileConsent,
  RiskProfiles,
  UserStatus,
  User,
  BankAccount,
} from '~/api';
import { ProfileAuthorisersPage } from '~/pages/profile/profile-authorisers';
import { ProfileConsentPage } from '~/pages/profile/profile-consent';
import { ProfileMaintenancePage } from '~/pages/profile/profile-maintenance';
import { ProfileRiskProfilePage } from '~/pages/profile/profile-risk-profile';
import { ProfileTransactionsPage } from '~/pages/profile/profile-transactions';

import { resourceNames } from './resource-names';

const {
  defineFields,
  defineShowPage,
  defineCardSection,
  defineTabsSection,
  defineCustomSection,
  createTableComponent,
  createResourceEditDialog,
  createResourceCreateButton,
} = createHelpers<Profile>({
  resourceName: resourceNames.profile,
});

export const formatUserStatus = (status?: UserStatus) => {
  switch (status) {
    case UserStatus.Active:
      return 'success';
    case UserStatus.Inactive:
    case UserStatus.Dormant:
      return 'pending';
    case UserStatus.Closed:
      return 'danger';
    case UserStatus.ProfileLocked:
      return 'warn';
    default:
      return 'pending';
  }
};

export const customerResource = createResource({
  identifier: resourceNames.customer,
  name: resourceNames.profile,
  label: 'Customer Profiles',
  fields: defineFields([
    'id',
    'type',
    'fullName',
    'salutation',
    'phoneNo',
    'nationality',
    'idType',
    'idNo',
    'idExpiryDate',
    'dateOfBirth',
    'cityOfBirth',
    'countryOfBirth',
    'gender',
    'bumiputera',
    'race',
    'maritalStatus',
    'religion',
    'mothersMaidenName',
    'email',
    'permanentAddress',
    'mailingAddress',
    'city',
    'state',
    'postcode',
    'employmentStatus',
    'occupation',
    'jobIndustry',
    'profession',
    'companyName',
    'natureOfBusiness',
    'natureOfBusinessDisplay',
    'officePhoneNo',
    'estimatedNetWorth',
    'annualIncome',
    'sourceOfFund',
    'sourceOfFundDisplay',
    'purposeOfInvestment',
    'expectedInvestmentAmount',
    'frequencyOfTransaction',
    'politicalExposedPerson',
    'prominentRole',
    'taxResidency',
    'declaration',
    'safRating',
    'marketingPush',
    'amlSanctionScreeningPoint',
    'employmentType',
    'monthlyIncome',
    'sourceOfWealth',
    'sourceOfWealthDisplay',
    'riskProfile',
    'riskProfileAttachmentId',
    { users: ['id'] },
    { bankAccounts: ['bankCode', 'beneficiaryName', 'accountNo', 'isDefault'] },
  ]),
  defaultValues: {},
  list: {
    filters: {
      required: true,
      collapsible: 'expand-by-default',
      initial: [
        {
          field: 'type',
          operator: 'eq',
          value: AccountType.Corporate,
        },
      ],
    },
    makerChecker: {
      resourceName: resourceNames.profile,
      entityTypes: [
        ModificationEntityType.User,
        ModificationEntityType.ProfileConsent,
        ModificationEntityType.AuthorisedSignatory,
        ModificationEntityType.Profile,
      ],
    },
  },
  filterControls: {
    idNo: {
      type: 'text',
      config: {
        label: 'ID Number',
      },
      operator: 'contains',
    },
    type: {
      type: 'select',
      config: {
        label: 'Account Type',
        options: [
          { label: 'Individual', value: AccountType.Individual },
          { label: 'Corporate', value: AccountType.Corporate },
        ],
      },
      operator: 'eq',
    },
  },
  allowCreate: false,
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  columns: ({ LinkToDetails }) => [
    {
      id: 'companyName',
      header: 'Company Name',
      accessorKey: 'companyName',
      cell: (data) => {
        // return sentenceCase(data.cell.getValue<string>());
        const companyName = data.cell.getValue<string>();

        return (
          <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
            {companyName}
          </LinkToDetails>
        );
      },
    },
    {
      id: 'idNo',
      header: 'ID Number',
      accessorKey: 'idNo',
    },
    {
      id: 'fullName',
      header: 'User Full Name',
      accessorKey: 'fullName',
    },
    {
      id: 'email',
      header: 'User Email Address',
      accessorKey: 'email',
    },
  ],
  allowEdit: false,
  allowDelete: false,
  show: defineShowPage({
    extras: ({ currentTab, data }) => {
      const { canWrite } = useResourceAccess(resourceNames.profile);
      const ConsentCreateButton = createResourceCreateButton<
        ProfileConsent,
        CreateDataInput<ProfileConsent>
      >({
        resourceName: resourceNames.profileConsent,
        resourceLabel: 'Profile Consent',
        defaultValues: {
          recipientId: data?.idNo,
        },
        controls: {
          components: {
            recipientId: {
              type: 'hidden',
            },
            issuerId: {
              type: 'text',
              config: {
                label: 'Company ID Number',
                placeholder: 'Enter company ID number',
                required: true,
              },
            },
            approvedEmail: {
              type: 'text',
              config: {
                label: 'Dedicated Email Address',
                placeholder: 'Enter dedicated email address',
                required: true,
              },
            },
            consentType: {
              type: 'select',
              config: {
                label: 'Consent Type',
                placeholder: 'Select consent type',
                options: Object.values(ConsentType).map((consentType) => ({
                  label: consentType,
                  value: consentType,
                })),
                required: true,
              },
            },
            attachment: {
              type: 'image-upload',
              config: {
                label: 'Attachment',
                required: true,
              },
            },
          },
        },
      });

      const MaintenanceCreateButton = createResourceCreateButton<User, CreateDataInput<User>>({
        resourceName: resourceNames.user,
        resourceLabel: 'New User',
        defaultValues: {
          ['profiles.connect.0.id']: data?.id,
          status: UserStatus.Inactive,
        },
        controls: {
          components: {
            name: {
              type: 'text',
              config: {
                label: 'Full Name',
                placeholder: 'Enter full name',
                required: true,
              },
            },
            email: {
              type: 'text',
              config: {
                label: 'Email Address',
                placeholder: 'Enter email address',
                required: true,
                type: 'email',
              },
            },
            phoneNo: {
              type: 'text',
              config: {
                label: 'Phone No',
                placeholder: 'Enter phone number',
              },
            },
            hpPhoneNo: {
              type: 'text',
              config: {
                label: 'HP Phone No',
                placeholder: 'Enter HP phone number',
              },
            },
            status: {
              type: 'hidden',
            },
            ['profiles.connect.0.id']: {
              type: 'hidden',
            },
          },
        },
      });

      const AuthorisedSignatoryCreateButton = createResourceCreateButton<
        User,
        CreateDataInput<User>
      >({
        resourceName: resourceNames.authorisedSignatory,
        resourceLabel: 'Authorised Signatory',
        bulkCreate: true,
        defaultValues: {
          companyProfileId: data?.id,
        },
        controls: {
          components: {
            fullName: {
              type: 'text',
              config: {
                label: 'Full Name',
                required: true,
              },
            },
            email: {
              type: 'text',
              config: {
                label: 'Email Address',
                required: true,
                type: 'email',
              },
            },
            attachment: {
              type: 'image-upload',
              config: {
                label: 'Attachment',
                required: false,
              },
            },
            companyProfileId: {
              type: 'hidden',
            },
          },
        },
      });

      const RiskProfileEditButton = createResourceEditDialog<Profile, CreateDataInput<Profile>>({
        resourceName: resourceNames.profile,
        resourceLabel: 'Risk Profile',
        defaultValues: {
          riskProfile: data?.riskProfile,
          riskProfileUpdatedAt: new Date().toISOString(),
        },
        fields: ['riskProfile'],
        controls: {
          components: {
            riskProfile: {
              type: 'select',
              config: {
                label: 'Risk Profile',
                options: Object.values(RiskProfiles).map((riskProfile) => ({
                  label: riskProfile,
                  value: riskProfile,
                })),
              },
            },
            riskProfileUpdatedAt: {
              type: 'hidden',
            },
            ...(!data?.riskProfileAttachmentId && {
              riskProfileAttachment: {
                type: 'image-upload',
                config: {
                  label: 'Attachment',
                  required: false,
                },
              },
            }),
          },
        },
      }).Button;

      return (
        <>
          {currentTab.value === 'profile-consent' ? (
            <ConsentCreateButton
              disabled={!canWrite}
              variant="solid"
              size="sm"
              className="min-w-[7rem]"
              icon={<HiPlusCircle />}
            />
          ) : currentTab.value === 'maintenance' ? (
            <MaintenanceCreateButton
              disabled={!canWrite}
              variant="solid"
              size="sm"
              className="min-w-[7rem]"
              icon={<HiPlusCircle />}
            />
          ) : currentTab.value === 'authorised-signatory' ? (
            <AuthorisedSignatoryCreateButton
              disabled={!canWrite}
              variant="solid"
              size="sm"
              className="min-w-[7rem]"
              icon={<HiPlusCircle />}
            />
          ) : currentTab.value === 'risk-profile' ? (
            <RiskProfileEditButton
              disabled={!canWrite}
              variant="solid"
              size="sm"
              className="min-w-[7rem]"
              icon={<HiPencil />}
              resourceId={data?.id ?? ''}
            />
          ) : undefined}
        </>
      );
    },
    sections: [
      defineTabsSection({
        tabs: [
          {
            label: 'Personal Information',
            content: [
              defineCardSection({
                title: 'Company Details',
                fields: [
                  { accounts: ['masterAccountNo'] },
                  'businessCategoryDisplay',
                  'createdAt',
                  'idType',
                  'idNo',
                  'permanentAddress',
                  'mailingAddress',
                  'city',
                  'state',
                  'postcode',
                ],
                displays: {
                  'accounts.masterAccountNo': {
                    label: 'Master account number(s)',
                    render: (data) =>
                      data.accounts.map((account) => account.masterAccountNo).join(', '),
                  },
                },
                collapsible: 'expand-by-default',
              }),
              defineCardSection({
                title: 'Investment Account Details',
                fields: [
                  'marketingPush',
                  'highNetWorth',
                  'phoneNo',
                  'officePhoneNo',
                  'email',
                  'natureOfBusinessDisplay',
                  'sourceOfFundDisplay',
                  'sourceOfWealthDisplay',
                ],
                displays: {
                  marketingPush: {
                    label: 'Marketing Push',
                    render: (data) => <Switch checked={data.marketingPush === true} disabled />,
                  },
                  highNetWorth: {
                    label: 'High Net Worth',
                    render: (data) => <Switch checked={data.highNetWorth === true} disabled />,
                  },
                  natureOfBusinessDisplay: {
                    label: 'Nature of Business',
                    render: (data) => data.natureOfBusinessDisplay,
                  },
                  sourceOfFundDisplay: {
                    label: 'Source of Fund',
                    render: (data) => data.sourceOfFundDisplay,
                  },
                  sourceOfWealthDisplay: {
                    label: 'Source of Wealth',
                    render: (data) => data.sourceOfWealthDisplay,
                  },
                },
                collapsible: 'expand-by-default',
              }),
              defineCardSection({
                title: 'Risk Profile',
                fields: ['riskProfile'],
                displays: {
                  riskProfile: {
                    label: 'Risk Profile',
                    render: (data, modifications) => {
                      if (!modifications) {
                        return data.riskProfile;
                      }

                      return (
                        <modifications.ModificationDiff
                          oldValue={modifications.oldValues?.riskProfile}
                          newValue={modifications.newValues?.riskProfile}
                        />
                      );
                    },
                  },
                },
                collapsible: 'expand-by-default',
              }),
              defineCustomSection({
                fields: [
                  { bankAccounts: ['bankCode', 'beneficiaryName', 'accountNo', 'isDefault'] },
                ],
                render: ({ queryResult }) => {
                  const bankAccounts = queryResult?.data?.data?.bankAccounts || [];

                  return (
                    <Card>
                      <CardHeader bordered>
                        <CardTitle>Bank Details</CardTitle>
                      </CardHeader>
                      <CardContent>
                      <div className="rounded-md border mb-4 mt-4">
                        <Table>
                          <TableHeader>
                            <TableRow>
                              <TableHead>Bank Code</TableHead>
                              <TableHead>Beneficiary Name</TableHead>
                              <TableHead>Account Number</TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {bankAccounts.map((account, index) => (
                              <TableRow key={index}>
                                <TableCell>{account.bankCode || '-'}</TableCell>
                                <TableCell>{account.beneficiaryName || '-'}</TableCell>
                                <TableCell>{account.accountNo || '-'}</TableCell>
                              </TableRow>
                            ))}
                            {bankAccounts.length === 0 && (
                              <TableRow>
                                <TableCell colSpan={4} className="text-center py-4">
                                  No bank accounts found
                                </TableCell>
                              </TableRow>
                            )}
                            </TableBody>
                          </Table>
                        </div>
                      </CardContent>
                    </Card>
                  );
                },
              }),
            ],
          },
          {
            label: 'Transactions',
            content: [
              defineCustomSection({
                fields: ['id'],
                render: ({ queryResult }) => (
                  <ProfileTransactionsPage
                    createTableComponent={createTableComponent}
                    profileId={queryResult?.data?.data?.id}
                  />
                ),
              }),
            ],
          },
          {
            label: 'Authorised Signatory',
            content: [
              defineCustomSection({
                fields: ['id'],
                render: ({ queryResult }) => (
                  <ProfileAuthorisersPage
                    createTableComponent={createTableComponent}
                    profileId={queryResult?.data?.data?.id}
                  />
                ),
              }),
            ],
          },
          {
            label: 'Risk Profile',
            content: [
              defineCustomSection({
                fields: ['riskProfile', 'riskProfileUpdatedAt', 'riskProfileAttachmentId'],
                render: ({ queryResult }) => (
                  <ProfileRiskProfilePage profile={queryResult?.data?.data} />
                ),
              }),
            ],
          },
          {
            label: 'Profile Consent',
            content: [
              defineCustomSection({
                fields: ['id'],
                render: ({ queryResult }) => (
                  <ProfileConsentPage
                    createTableComponent={createTableComponent}
                    profileId={queryResult?.data?.data?.id}
                  />
                ),
              }),
            ],
          },
          {
            label: 'Maintenance',
            content: [
              defineCustomSection({
                fields: ['id'],
                render: ({ queryResult }) => (
                  <ProfileMaintenancePage
                    createTableComponent={createTableComponent}
                    createResourceEditDialog={createResourceEditDialog}
                    profileId={queryResult?.data?.data?.id}
                  />
                ),
              }),
            ],
          },
        ],
      }),
    ],
  }),
});
