import { Button, cn } from '@amfintech/react-admin-ui';
import { capitalCase } from 'change-case';

import { Role, useAdminPermissionMapping } from '~/hooks/use-admin-permissions-mapping';

type RoleListProps = {
  appliedPermissions: Set<string>;
  handleAccessChange: (code: string) => void;
  onlyShowAppliedPermissions?: boolean;
  disabled?: boolean;
};

const accessTypes = ['access', 'maker', 'checker'] as const;

export const RoleList = ({
  appliedPermissions,
  handleAccessChange,
  onlyShowAppliedPermissions = false,
  disabled = false,
}: RoleListProps) => {
  const { isLoading, result: permissions } = useAdminPermissionMapping();

  return (
    <>
      {!isLoading &&
        Object.values(permissions).map((permission: Role) => {
          const hasAccess =
            appliedPermissions.has(permission.access) ||
            appliedPermissions.has(permission.maker) ||
            appliedPermissions.has(permission.checker);
          return (
            <div key={permission.access || `${permission.name}-${permission.maker}`}>
              {onlyShowAppliedPermissions && !hasAccess ? null : (
                <div >
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-medium">{permission.name}</span>
                    <div className="flex py-2 space-x-2">
                      {accessTypes.map((accessType) => (
                        <Button
                          key={permission[accessType]}
                          variant={
                            appliedPermissions.has(permission[accessType]) ? 'twoTone' : 'outline'
                          }
                          size="sm"
                          className={cn('border border-gray-300')}
                          onClick={(event) => {
                            event.preventDefault();
                            handleAccessChange(permission[accessType]);
                          }}
                          disabled={!permission[accessType] || disabled}
                        >
                          {capitalCase(accessType)}
                        </Button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
    </>
  );
};
