import {
  AlertDialog,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@amfintech/react-admin-ui';
import { Content, createHelpers } from '@amfintech/refine-react-admin';
import { useCustomMutation, useInvalidate, useLink, useNavigation } from '@refinedev/core';
import dayjs from 'dayjs';

import { TomswinSyncBatch, TomswinSyncBatchStatus } from '~/api';
import { resourceNames } from '~/resources/resource-names';

import { useGetTomswinSyncAggregatedData } from './hooks/useGetTomswinSyncAggregatedData';

const { defineFields, createTableComponent, createResourceEditDialog, createResourceCreateButton } =
  createHelpers<TomswinSyncBatch>({
    resourceName: resourceNames.tomswinSyncBatch,
  });

export default function DeveloperToolsDashboard() {
  const Link = useLink();
  const { listUrl } = useNavigation();
  const invalidate = useInvalidate();

  const { getTomswinSyncAggregatedDataByBatchId, invalidate: invalidateAggregatedData } =
    useGetTomswinSyncAggregatedData();

  const customMutation = useCustomMutation();

  const handleClearIndex = async (batchId: string) => {
    const { data } = await customMutation.mutateAsync({
      method: 'post',
      url: '',
      values: {},
      metaData: {
        operation: 'tomswinSyncRemoveIndex',
        variables: {
          batchId: {
            type: 'String!',
            value: batchId,
          },
        },
      },
    });

    if (data) {
      // Invalidate the query
      invalidate({
        resource: resourceNames.tomswinSyncBatch,
        invalidates: ['list'],
      });
      invalidateAggregatedData();
    }
  };

  const getBatchStatusLabel = (status: string) => {
    if (status === 'Identified') {
      // Color grey
      return (
        <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm dark:bg-blue-900 dark:text-blue-300">
          Identified
        </span>
      );
    }
    if (status === 'Indexed') {
      return (
        <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm dark:bg-green-900 dark:text-green-300">
          Indexed
        </span>
      );
    }
    if (status === 'Ignored') {
      return (
        <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm dark:bg-gray-700 dark:text-gray-300">
          Ignored
        </span>
      );
    }
    if (status === 'Cleared') {
      return (
        <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm dark:bg-gray-700 dark:text-gray-300">
          Cleared
        </span>
      );
    }
    return <span className="text-gray-500">{status}</span>;
  };

  const TomswinSyncBatchesTableComponent = createTableComponent<TomswinSyncBatch>({
    tableVariant: 'simple',
    resource: resourceNames.tomswinSyncBatch,
    fields: [
      'fileLastModifiedAt',
      'batchId',
      'fileType',
      'fileName',
      'indexedLineNo',
      'status',
      'totalLineNo',
    ],
    columns: () => [
      {
        id: 'fileLastModifiedAt',
        header: 'File date',
        accessorKey: 'fileLastModifiedAt',
        cell: (data) => {
          return dayjs(data.cell.getValue<string>()).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        id: 'batchId',
        header: 'Batch ID',
        accessorKey: 'batchId',
        cell: (data) => {
          return (
            <Link
              target="_blank"
              to={
                listUrl(resourceNames.tomswinSync) +
                `?filters[0][field]=batchId&filters[0][operator]=eq&filters[0][value]=${data.cell.getValue<string>()}`
              }
            >
              {data.cell.getValue<string>()}
            </Link>
          );
        },
      },
      {
        id: 'fileName',
        header: 'File Name/Type',
        accessorKey: 'fileName',
        cell: (data) => {
          const fileName = data.cell.getValue<string>();
          const fileType = data.row.original.fileType;
          return `${fileName} ${fileType ? `(${fileType})` : ''}`;
        },
      },
      {
        id: 'status',
        header: 'Status',
        accessorKey: 'status',
        cell: (data) => {
          return getBatchStatusLabel(data.cell.getValue<string>());
        },
      },
      {
        id: 'totalLineNo',
        header: 'Total Line',
        accessorKey: 'totalLineNo',
        cell: (data) => {
          return data.cell.getValue<number>();
        },
      },
      {
        id: 'indexedLineNo',
        header: 'Indexed Line',
        accessorKey: 'indexedLineNo',
        cell: (data) => {
          return data.cell.getValue<number>();
        },
      },
      {
        id: 'pendingCount',
        header: 'Pending',
        accessorKey: 'pendingCount',
        cell: (data) => {
          const pendingCount = getTomswinSyncAggregatedDataByBatchId(data.row.original.batchId)
            ?.pendingCount;

          if (!Number.isInteger(pendingCount)) {
            return <span className="text-gray-600">-</span>;
          }

          return (
            <span className="text-gray-600">
              {getTomswinSyncAggregatedDataByBatchId(data.row.original.batchId)?.pendingCount}
            </span>
          );
        },
      },
      {
        id: 'failedCount',
        header: 'Failed',
        accessorKey: 'failedCount',
        cell: (data) => {
          const failedCount = getTomswinSyncAggregatedDataByBatchId(data.row.original.batchId)
            ?.failedCount;

          if (!Number.isInteger(failedCount)) {
            return <span className="text-gray-600">-</span>;
          }

          return (
            <span className="text-red-600">
              {getTomswinSyncAggregatedDataByBatchId(data.row.original.batchId)?.failedCount}
            </span>
          );
        },
      },
      {
        id: 'completedCount',
        header: 'Completed',
        accessorKey: 'completedCount',
        cell: (data) => {
          const completedCount = getTomswinSyncAggregatedDataByBatchId(data.row.original.batchId)
            ?.completedCount;

          if (!Number.isInteger(completedCount)) {
            return <span className="text-gray-600">-</span>;
          }
          return (
            <span className="text-green-600">
              {getTomswinSyncAggregatedDataByBatchId(data.row.original.batchId)?.completedCount}
            </span>
          );
        },
      },
      {
        id: 'lastUpdatedAt',
        header: 'Last Updated At',
        accessorKey: 'lastUpdatedAt',
        cell: (data) => {
          const lastUpdatedAt = getTomswinSyncAggregatedDataByBatchId(data.row.original.batchId)
            ?.lastUpdatedAt;

          if (!lastUpdatedAt) {
            return <span className="text-gray-600">-</span>;
          }

          return dayjs(lastUpdatedAt).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        id: 'action',
        header: 'Action',
        accessorKey: 'batchId',
        cell: (data) => {
          if (data.row.original.status !== TomswinSyncBatchStatus.Indexed) {
            return <></>;
          }

          return (
            <AlertDialog.Root>
              <AlertDialog.Trigger asChild>
                <Button variant="danger">Clear index</Button>
              </AlertDialog.Trigger>
              <AlertDialog.Portal>
                <AlertDialog.Overlay />
                <AlertDialog.Content>
                  <AlertDialog.Title>Clear index</AlertDialog.Title>
                  <AlertDialog.Description>
                    This action is undoable. Are you sure you want to proceed?
                  </AlertDialog.Description>
                  <AlertDialog.Footer className="justify-end">
                    <AlertDialog.Cancel>
                      <Button>Cancel</Button>
                    </AlertDialog.Cancel>
                    <AlertDialog.Action>
                      <Button
                        variant="danger"
                        onClick={() => handleClearIndex(data.cell.getValue<string>())}
                      >
                        Clear
                      </Button>
                    </AlertDialog.Action>
                  </AlertDialog.Footer>
                </AlertDialog.Content>
              </AlertDialog.Portal>
            </AlertDialog.Root>
          );
        },
      },
    ],
  });

  return (
    <div className="w-full">
      <Content title="Developer Tools">
        <div className="w-full grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4">
          <Card>
            <CardHeader>
              <CardTitle>Tomswin Sync Batches</CardTitle>
            </CardHeader>
            <CardContent>
              <TomswinSyncBatchesTableComponent />
            </CardContent>
          </Card>
        </div>
      </Content>
    </div>
  );
}
