import { StatusField } from '@amfintech/react-admin-ui';
import { createHelpers, createResource } from '@amfintech/refine-react-admin';
import dayjs from 'dayjs';

import { AccountType, TomswinSync, TomswinSyncStatus, User } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineShowPage, defineCardSection } = createHelpers<TomswinSync>({
  resourceName: resourceNames.tomswinSync,
});

const formatStatus = (status: TomswinSyncStatus) => {
  switch (status) {
    case TomswinSyncStatus.Completed:
      return 'success';
    case TomswinSyncStatus.Failed:
      return 'danger';
    default:
      return 'pending';
  }
};

export const tomswinSyncResource = createResource({
  name: resourceNames.tomswinSync,
  label: 'Tomswin Sync',
  fields: defineFields([
    'id',
    'batchId',
    'fileName',
    'fileType',
    'data',
    'failureReason',
    'status',
    'lineNo',
    'fileLastModifiedAt',
    'createdAt',
    'updatedAt',
  ]),
  allowDownload: false,
  allowCreate: false,
  allowDelete: false,
  allowEdit: false,
  list: {
    syncWithLocation: true,
    filters: {
      required: true,
      collapsible: 'expand-by-default',
    },
  },
  defaultValues: {},
  defaultSorter: [
    {
      field: 'createdAt',
      order: 'desc',
    },
  ],
  defaultPageSize: 200,
  timeSensitive: {
    pollingInterval: 10000,
    systemNotification: true,
  },
  filterControls: {
    createdAt: {
      type: 'daterange',
      config: {
        label: 'Indexed date',
      },
    },
    batchId: {
      type: 'text',
      config: {
        label: 'Batch ID',
      },
      operator: 'eq',
    },
    fileType: {
      type: 'select',
      config: {
        label: 'File type',
        options: [
          { label: 'FMD_FUND', value: 'FMD_FUND' },
          { label: 'FMD_MGT', value: 'FMD_MGT' },
          { label: 'FMD_FUND_POL', value: 'FMD_FUND_POL' },
          { label: 'FMD_PF', value: 'FMD_PF' },
          { label: 'FMD_NAV', value: 'FMD_NAV' },
          { label: 'FMD_CAL', value: 'FMD_CAL' },
          { label: 'FMD_CALF', value: 'FMD_CALF' },
          { label: 'FMD_CIF', value: 'FMD_CIF' },
          { label: 'FMD_TRUSTEE', value: 'FMD_TRUSTEE' },
          { label: 'FMD_MACC', value: 'FMD_MACC' },
          { label: 'FMD_UTACC', value: 'FMD_UTACC' },
          { label: 'FMD_TRX_HIST', value: 'FMD_TRX_HIST' },
          { label: 'FMD_TRX_STATUS', value: 'FMD_TRX_STATUS' },
          { label: 'FMD_BANK', value: 'FMD_BANK' },
          { label: 'FMD_ADR', value: 'FMD_ADR' },
          { label: 'FundFullList', value: 'FundFullList' },
          { label: 'CollectionBankAccounts', value: 'CollectionBankAccounts' },
        ],
      },
      operator: 'eq',
    },
    status: {
      type: 'select',
      config: {
        label: 'Status',
        options: [
          { label: 'Completed', value: 'Completed' },
          { label: 'Failed', value: 'Failed' },
          { label: 'Pending', value: 'Pending' },
        ],
      },
      operator: 'eq',
    },
  },
  columns: ({ LinkToDetails }) => [
    {
      id: 'createdAt',
      header: 'Indexed time',
      accessorKey: 'createdAt',
      cell: (data) => {
        const createdAt = data.cell.getValue<string>();

        return (
          <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
            {dayjs(createdAt).format('DD MMM YYYY HH:mm:ss')}
          </LinkToDetails>
        );
      },
    },
    {
      id: 'fileLastModifiedAt',
      header: 'File Modified At',
      accessorKey: 'fileLastModifiedAt',
      cell: (data) => {
        const fileLastModifiedAt = data.cell.getValue<string>();

        return <>{dayjs(fileLastModifiedAt).format('DD MMM YYYY HH:mm:ss')}</>;
      },
    },
    {
      id: 'updatedAt',
      header: 'Processed At',
      accessorKey: 'updatedAt',
      cell: (data) => {
        const updatedAt = data.cell.getValue<string>();

        return <>{dayjs(updatedAt).format('DD MMM YYYY HH:mm:ss')}</>;
      },
    },
    {
      id: 'batchId',
      header: 'Batch ID',
      accessorKey: 'batchId',
      cell: (data) => {
        const batchId = data.cell.getValue<string>();

        return <>{batchId}</>;
      },
    },
    {
      id: 'fileName',
      header: 'File Name',
      accessorKey: 'fileName',
      cell: (data) => {
        const fileName = data.cell.getValue<string>();

        return <>{fileName}</>;
      },
    },
    {
      id: 'fileType',
      header: 'File Type',
      accessorKey: 'fileType',
      cell: (data) => {
        const fileType = data.cell.getValue<string>();

        return <>{fileType}</>;
      },
    },
    {
      id: 'lineNo',
      header: 'Line No',
      accessorKey: 'lineNo',
      cell: (data) => {
        const lineNo = data.cell.getValue<number>();

        return <>{lineNo}</>;
      },
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      cell: (data) => {
        const status = data.cell.getValue<TomswinSyncStatus>();

        // If status is failed, show the status and failure reason
        if (status === TomswinSyncStatus.Failed) {
          const failureReason = data.row.original.failureReason;

          return (
            <>
              <StatusField status={formatStatus(status)} label={status} />
              <p className="text-sm text-gray-500">{failureReason}</p>
            </>
          );
        }

        return <StatusField status={formatStatus(status)} label={status} />;
      },
    },
  ],
});
