import { useCustom } from '@refinedev/core';
import { useQueryClient } from '@tanstack/react-query';

export const useGetTomswinSyncAggregatedData = () => {
  const queryClient = useQueryClient();
  const queryKey = ['tomswinSyncCurrent'];

  const query = useCustom<
    {
      batchId: string;
      fileType: string;
      lastUpdatedAt: string;
      count: number;
      pendingCount: number;
      failedCount: number;
      completedCount: number;
    }[]
  >({
    method: 'get',
    url: '',
    metaData: {
      operation: 'tomswinSyncCurrent',
      fields: [
        'batchId',
        'fileType',
        'lastUpdatedAt',
        'count',
        'pendingCount',
        'failedCount',
        'completedCount',
      ],
    },
    queryOptions: {
      refetchInterval: 10000,
      queryKey,
    },
  });

  const getTomswinSyncAggregatedDataByBatchId = (batchId: string) => {
    return query.data?.data?.find((item) => item.batchId === batchId);
  };

  const invalidate = () => {
    queryClient.invalidateQueries(queryKey);
  };

  return {
    ...query,
    getTomswinSyncAggregatedDataByBatchId,
    invalidate,
  };
};
