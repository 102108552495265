import { useState } from 'react';
import { HiOutlineEye, HiOutlinePencil } from 'react-icons/hi';

import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  ImageViewer,
  StatusField,
  formatDate,
} from '@amfintech/react-admin-ui';
import { DynamicForm, ResourceTableConfig, useResourceAccess } from '@amfintech/refine-react-admin';
import { useDelete } from '@refinedev/core';
import { useForm } from '@refinedev/react-hook-form';

import { AuthorisedSignatory, AuthorisedSignatoryStatus, CreationStatus } from '~/api';
import { resourceNames } from '~/resources/resource-names';

const formatStatus = (status: AuthorisedSignatoryStatus) => {
  switch (status) {
    case AuthorisedSignatoryStatus.Active:
      return 'success';
    case AuthorisedSignatoryStatus.Revoked:
      return 'danger';
    default:
      return 'pending';
  }
};
export const ProfileAuthorisersPage = ({
  createTableComponent,
  profileId,
}: {
  createTableComponent: <T extends object>(props: ResourceTableConfig<T>) => () => React.ReactNode;
  profileId?: string;
}) => {
  const { canWrite } = useResourceAccess(resourceNames.profile);

  const tableComponent = createTableComponent<AuthorisedSignatory>({
    resource: resourceNames.authorisedSignatory,
    fields: ['id', 'fullName', 'email', 'attachment', 'status', 'updatedAt'],
    columns: () => [
      {
        id: 'fullName',
        header: 'Full Name',
        accessorKey: 'fullName',
      },
      {
        id: 'email',
        header: 'Email',
        accessorKey: 'email',
      },
      {
        id: 'status',
        header: 'Status',
        accessorKey: 'status',
        cell: (data) => {
          const status = data.cell.getValue<AuthorisedSignatoryStatus>();
          return <StatusField status={formatStatus(status)} label={status} />;
        },
      },
      {
        id: 'updatedAt',
        header: 'Last Modified',
        accessorKey: 'updatedAt',
        cell: (data) => {
          return formatDate(data.cell.getValue<Date>());
        },
      },
      {
        id: 'actions',
        header: 'Actions',
        accessorKey: 'actions',
        cell: (data) => {
          const attachment = data.row.original.attachment;
          const [isShowEditDialog, setIsShowEditDialog] = useState(false);
          const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);

          const { mutate: deleteAuthoriser, isLoading: isDeleting } = useDelete();

          const handleDelete = () => {
            deleteAuthoriser(
              {
                resource: resourceNames.authorisedSignatory,
                id: data.row.original.id,
              },
              {
                onSuccess: () => {
                  setIsShowDeleteDialog(false);
                },
              }
            );
          };

          const editForm = useForm<AuthorisedSignatory>({
            refineCoreProps: {
              resource: resourceNames.authorisedSignatory,
              action: 'edit',
              redirect: false,
              errorNotification: false,
              onMutationSuccess: () => setIsShowEditDialog(false),
              id: data.row.original.id,
              metaData: {
                fields: ['status'] as any[],
              },
            },
          });

          return (
            <div className="flex flex-row gap-2">
              {attachment && (
                <Dialog>
                  <DialogTrigger asChild>
                    <Button variant={'solid'} size="sm" className="gap-2">
                      <HiOutlineEye className="h-4 w-4" />
                      View
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="max-h-[80vh] max-w-[60vw] w-[fit-content] overflow-y-auto">
                    <ImageViewer src={attachment} alt="Attachment" />
                  </DialogContent>
                </Dialog>
              )}
              <Dialog open={isShowEditDialog} onOpenChange={setIsShowEditDialog}>
                <DialogTrigger asChild>
                  <Button disabled={!canWrite} variant={'solid'} size="sm" className="gap-2 min-w-[6rem]">
                    Edit
                  </Button>
                </DialogTrigger>
                <DialogPortal>
                  <DialogOverlay />
                  <DialogContent>
                    <DialogTitle>Edit Authoriser</DialogTitle>
                    <div className="lg:min-w-full">
                      <div className="py-3">
                        <DynamicForm<AuthorisedSignatory>
                          form={editForm}
                          defaultValues={{ status: data.row.original.status }}
                          controls={{
                            components: {
                              status: {
                                type: 'select',
                                config: {
                                  label: 'Status',
                                  options: [
                                    { label: 'Active', value: AuthorisedSignatoryStatus.Active },
                                    { label: 'Revoked', value: AuthorisedSignatoryStatus.Revoked },
                                  ],
                                },
                              },
                            },
                          }}
                          submitText={false}
                          id={'edit-profile-consent-dialog'}
                          inferSections={false}
                          type={'edit'}
                        />
                      </div>
                      <div className="flex flex-row-reverse gap-3 pt-2 border-t">
                        <Button
                          type="submit"
                          variant="solid"
                          loading={editForm.refineCore.mutationResult.isLoading}
                          disabled={editForm.refineCore.formLoading}
                          form={'edit-profile-consent-dialog'}
                        >
                          Save
                        </Button>
                        <Button
                          onClick={() => setIsShowEditDialog(false)}
                          disabled={
                            editForm.refineCore.formLoading ||
                            editForm.refineCore.mutationResult.isLoading
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </DialogContent>
                </DialogPortal>
              </Dialog>
              <Dialog open={isShowDeleteDialog} onOpenChange={setIsShowDeleteDialog}>
                <DialogTrigger asChild>
                  <Button disabled={!canWrite} variant={'solid'} size="sm" className="gap-2 min-w-[6rem]">
                    Delete
                  </Button>
                </DialogTrigger>
                <DialogPortal>
                  <DialogOverlay />
                  <DialogContent>
                    <DialogTitle>Delete Authoriser</DialogTitle>
                    <DialogDescription>
                      This action is undoable. Do you want to proceed?
                    </DialogDescription>
                    <DialogFooter>
                      <Button onClick={() => setIsShowDeleteDialog(false)} disabled={isDeleting}>
                        Cancel
                      </Button>
                      <Button onClick={handleDelete} variant="solid" loading={isDeleting}>
                        Delete
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </DialogPortal>
              </Dialog>
            </div>
          );
        },
      },
    ],
    filterConfig: {
      permanent: [
        {
          field: 'companyProfileId' as any,
          operator: 'eq',
          value: profileId,
        },
        {
          field: 'creationStatus',
          operator: 'eq',
          value: CreationStatus.Approved,
        },
      ],
    },
  });

  return tableComponent();
};
