import { Button, formatDate } from '@amfintech/react-admin-ui';
import { createHelpers, createResource } from '@amfintech/refine-react-admin';
import { useNavigation } from '@refinedev/core';

import { FundHoliday, ModificationEntityType, ModificationType } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineShowPage, defineResourceSelect, defineCardSection } =
  createHelpers<FundHoliday>({
    resourceName: resourceNames.fundHoliday,
  });

export const fundHolidayResource = createResource({
  name: resourceNames.fundHoliday,
  label: 'Fund Holiday',
  fields: defineFields([
    'id',
    'fundId',
    'remarks',
    'startDate',
    'endDate',
    {
      fund: ['id', 'fundName', 'fundCode'],
    },
  ]),
  allowDownload: true,
  list: {
    downloadConfig: {
      startDate: (value: string) => formatDate(value, { formatType: 'dateOnly' }),
      endDate: (value: string) => formatDate(value, { formatType: 'dateOnly' }),
    },
    filters: {
      collapsible: 'expand-by-default',
    },
    makerChecker: {
      entityTypes: [ModificationEntityType.FundHoliday],
    },
  },
  defaultValues: {},
  controls: {
    components: {
      fundId: defineResourceSelect({
        label: 'Fund Name',
        fields: ['fundName', 'fundCode', 'id'],
        resourceName: resourceNames.fund,
        optionLabel: (data) => `${data.fundCode} - ${data.fundName} `,
        optionValue: 'id',
        required: true,
      }),
      remarks: {
        type: 'textarea',
        config: {
          label: 'Remarks',
        },
      },
      startDate: {
        type: 'date',
        config: {
          label: 'Start Date',
          required: true,
          side: 'right',
        },
      },
      endDate: {
        type: 'date',
        config: {
          label: 'End Date',
          required: true,
          side: 'right',
        },
      },
    },
  },
  filterControls: {
    fundId: {
      type: 'resource-select',
      config: {
        label: 'Fund Name',
        fields: ['fundName', 'id', 'fundCode'],
        resourceName: resourceNames.fund,
        optionLabel: (data: any) => `${data.fundCode} - ${data.fundName} `,
        optionValue: 'id',
        options: [], // This gets populated by the useResourceSelect hook
        pagination: {
          pageSize: 1000,
        },
      },
    },
    startDate: {
      type: 'date',
      config: {
        label: 'Start Date',
      },
      operator: 'gte',
    },
    endDate: {
      type: 'date',
      config: {
        label: 'End Date',
      },
      operator: 'lte',
    },
  },
  columns: ({ LinkToDetails }) => [
    {
      id: 'fund.fundName',
      header: 'Fund Name',
      accessorKey: 'fund.fundName',
      cell: (data) => (
        <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
          {data.getValue<string>()}
        </LinkToDetails>
      ),
    },
    {
      id: 'fund.fundCode',
      header: 'Fund Code',
      accessorKey: 'fund.fundCode',
    },
    {
      id: 'remarks',
      header: 'Remarks',
      accessorKey: 'remarks',
      cell: (data) => {
        const value = data.getValue<string>();

        if (!value) {
          return '-';
        }

        return value;
      },
    },
    {
      id: 'startDate',
      header: 'Start Date',
      accessorKey: 'startDate',
      cell: (data) => formatDate(data.getValue<string>(), { formatType: 'dateOnly' }),
    },
    {
      id: 'endDate',
      header: 'End Date',
      accessorKey: 'endDate',
      cell: (data) => formatDate(data.getValue<string>(), { formatType: 'dateOnly' }),
    },
  ],
  allowEdit: false,
  renderEditPage: true,
  show: defineShowPage({
    actions: (_, option) => {
      if (option?.modification) {
        return <></>;
      }

      const navigateTo = useNavigation();
      const isExpired = option?.data?.endDate ? new Date(option.data.endDate) < new Date() : false;

      return (
        <>
          <Button
            disabled={!option?.data?.id || isExpired}
            onClick={() => navigateTo.edit(resourceNames.fundHoliday, option!.data!.id)}
            variant="solid"
            size="sm"
            className="min-w-[6rem]"
          >
            Edit
          </Button>
        </>
      );
    },
    sections: [
      defineCardSection({
        title: 'Details',
        fields: [
          {
            fund: ['fundName', 'fundCode'],
          },
          'startDate',
          'endDate',
          'createdAt',
          'updatedAt',
          'remarks',
        ],
        displays: {
          'fund.fundName': {
            label: 'Fund Name',
            render: (data, modifications) => {
              if (!modifications) {
                return data.fund?.fundName;
              }

              return (
                <modifications.ModificationDiff
                  oldValue={modifications.oldValues?.fundName}
                  newValue={modifications.newValues?.fundName}
                  getModifiedResource={{
                    resourceName: resourceNames.fund,
                    oldId: modifications.oldValues?.fundId,
                    newId: modifications.newValues?.fundId,
                    field: 'fundName',
                  }}
                />
              );
            },
          },
          'fund.fundCode': {
            label: 'Fund Code',
            render: (data, modifications) => {
              if (!modifications) {
                return <>{data.fund?.fundCode}</>;
              }

              return (
                <modifications.ModificationDiff
                  oldValue={modifications.oldValues?.fundCode}
                  newValue={modifications.newValues?.fundCode}
                  getModifiedResource={{
                    resourceName: resourceNames.fund,
                    oldId: '',
                    newId: modifications.newValues?.fundId,
                    field: 'fundCode',
                  }}
                />
              );
            },
          },
          startDate: {
            label: 'Start date',
            render: (data, modifications) => {
              if (!modifications) {
                return formatDate(data.startDate, { format: 'yyyy-MM-dd' });
              }

              return (
                <modifications.ModificationDiff
                  oldValue={
                    modifications.type === ModificationType.Create
                      ? ''
                      : formatDate(modifications.oldValues?.startDate, {
                          format: 'yyyy-MM-dd',
                        })
                  }
                  newValue={formatDate(modifications.newValues?.startDate, {
                    format: 'yyyy-MM-dd',
                  })}
                />
              );
            },
          },
          endDate: {
            label: 'End date',
            render: (data, modifications) => {
              if (!modifications) {
                return formatDate(data.endDate, { format: 'yyyy-MM-dd' });
              }

              return (
                <modifications.ModificationDiff
                  oldValue={
                    modifications.type === ModificationType.Create
                      ? ''
                      : formatDate(modifications.oldValues?.endDate, {
                          format: 'yyyy-MM-dd',
                        })
                  }
                  newValue={formatDate(modifications.newValues?.endDate, { format: 'yyyy-MM-dd' })}
                />
              );
            },
          },
        },
      }),
    ],
  }),
});
