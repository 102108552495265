import { formatDate, StatusField } from '@amfintech/react-admin-ui';
import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import { Announcement, ModificationEntityType, ModificationType } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineShowPage, defineCardSection } = createHelpers<Announcement>({
  resourceName: resourceNames.announcement,
});

export const formatAnnouncementStatus = (status: string) => {
  switch (status) {
    case 'Active':
      return 'success';
    case 'Inactive':
      return 'danger';
    default:
      return 'pending';
  }
};

export const announcementResource = createResource({
  name: resourceNames.announcement,
  label: 'Announcement',
  fields: defineFields([
    'id',
    'title',
    'content',
    'ctaLink',
    'startDate',
    'endDate',
    'status',
    'createdAt',
    'updatedAt',
  ]),
  allowDownload: true,
  list: {
    filters: {
      collapsible: 'expand-by-default',
    },
    makerChecker: {
      entityTypes: [ModificationEntityType.Announcement],
    },
  },
  defaultValues: {},
  defaultSorter: [
    {
      field: 'createdAt',
      order: 'desc',
    },
  ],
  controls: {
    components: {
      title: {
        type: 'text',
        config: {
          label: 'Title',
        },
      },
      content: {
        type: 'textarea',
        config: {
          label: 'Content',
        },
      },
      ctaLink: {
        type: 'text',
        config: {
          label: 'CTA Link',
        },
      },
      startDate: {
        type: 'date',
        config: {
          defaultValue: new Date(),
          label: 'Start Date',
        },
      },
      endDate: {
        type: 'date',
        config: {
          defaultValue: new Date(),
          label: 'End Date',
        },
      },
      status: {
        type: 'select',
        config: {
          label: 'Status',
          defaultValue: 'Active',
          options: [
            {
              label: 'Active',
              value: 'Active',
            },
            {
              label: 'Inactive',
              value: 'Inactive',
            },
          ],
        },
      },
    },
    componentConfigDefaults: {
      span: 2,
    },
  },
  filterControls: {
    title: {
      type: 'text',
      config: {
        label: 'Title',
      },
      operator: 'contains',
    },
    startDate: {
      type: 'date',
      config: {
        label: 'Start Date',
      },
      operator: 'gte',
    },
    endDate: {
      type: 'date',
      config: {
        label: 'End Date',
      },
      operator: 'lte',
    },
    status: {
      type: 'select',
      config: {
        label: 'Status',
        options: [
          { label: 'Active', value: 'Active' },
          { label: 'Inactive', value: 'Inactive' },
        ],
      },
      operator: 'eq',
    },
  },
  columns: ({ LinkToDetails }) => [
    {
      id: 'title',
      header: 'Title',
      accessorKey: 'title',
      cell: (data) => (
        <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
          {data.getValue<string>()}
        </LinkToDetails>
      ),
    },
    {
      id: 'startDate',
      header: 'Start Date',
      accessorKey: 'startDate',
      cell: (data) => formatDate(data.getValue<string>(), { formatType: 'dateOnly' }),
    },
    {
      id: 'endDate',
      header: 'End Date',
      accessorKey: 'endDate',
      cell: (data) => formatDate(data.getValue<string>(), { formatType: 'dateOnly' }),
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      cell: (data) => {
        const status = data.getValue<string>();
        return <StatusField status={formatAnnouncementStatus(status)} label={status} />;
      },
    },
  ],
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'Description',
        fields: [
          'title',
          'content',
          'ctaLink',
          'startDate',
          'endDate',
          'status',
          'createdAt',
          'updatedAt',
        ],
        displays: {
          ctaLink: {
            label: 'CTA Link',
            render: (data: Announcement, modifications) => {
              if (modifications?.newValues?.ctaLink) {
                return (
                  <modifications.ModificationDiff
                    oldValue={modifications.oldValues.ctaLink}
                    newValue={modifications.newValues.ctaLink}
                  />
                );
              }

              if (!data.ctaLink) return <></>;
              return (
                <a
                  href={data.ctaLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline"
                >
                  {data.ctaLink}
                </a>
              );
            },
          },
          startDate: {
            label: 'Start date',
            render: (data, modifications) => {
              if (!modifications) {
                return formatDate(data.startDate, { format: 'yyyy-MM-dd' });
              }

              return (
                <modifications.ModificationDiff
                  oldValue={
                    modifications.type === ModificationType.Create
                      ? ''
                      : formatDate(modifications.oldValues?.startDate, {
                          format: 'yyyy-MM-dd',
                        })
                  }
                  newValue={formatDate(modifications.newValues?.startDate, {
                    format: 'yyyy-MM-dd',
                  })}
                />
              );
            },
          },
          endDate: {
            label: 'End date',
            render: (data, modifications) => {
              if (!modifications) {
                return formatDate(data.endDate, { format: 'yyyy-MM-dd' });
              }

              return (
                <modifications.ModificationDiff
                  oldValue={
                    modifications.type === ModificationType.Create
                      ? ''
                      : formatDate(modifications.oldValues?.endDate, {
                          format: 'yyyy-MM-dd',
                        })
                  }
                  newValue={formatDate(modifications.newValues?.endDate, { format: 'yyyy-MM-dd' })}
                />
              );
            },
          },
        },
      }),
    ],
  }),
});
