import { formatDate, StatusField } from '@amfintech/react-admin-ui';
import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import { AccountType, ApprovalStatus, type AccountRequest } from '~/api';

import { formatApprovalStatus } from './lead-generation-resource';
import { resourceNames } from './resource-names';

const { defineFields, defineCardSection, defineShowPage } = createHelpers<AccountRequest>({
  resourceName: resourceNames.accountRequest,
});

export const accountOpeningResource = createResource({
  identifier: resourceNames.accountOpening,
  name: resourceNames.accountRequest,
  label: 'Individual Account Opening',
  list: {
    filters: {
      collapsible: 'expand-by-default',
      permanent: [
        {
          field: 'type',
          operator: 'eq',
          value: AccountType.Individual,
        },
      ],
    },
  },
  filterControls: {
    referenceNo: {
      type: 'text',
      config: {
        label: 'Reference Number',
      },
      operator: 'contains',
    },
    idNo: {
      type: 'text',
      config: {
        label: 'ID Number',
      },
      operator: 'eq',
    },
    createdAt: {
      type: 'daterange',
      config: {
        label: 'Application Date',
        disabled: {
          after: new Date(),
        },
      },
    },
  },
  fields: defineFields([
    'id',
    'type',
    'name',
    'accountCreatedDate',
    'approvalStatus',
    'contactNo',
    'createdAt',
    'deletedAt',
    'idNo',
    'idType',
    'referenceNo',
    'updatedAt',
  ]),
  defaultValues: {},
  allowCreate: false,
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  columns: ({ LinkToDetails }) => [
    {
      id: 'referenceNo',
      header: 'Transaction Reference Number',
      accessorKey: 'referenceNo',
      cell: (data) => {
        const referenceNo = data.cell.getValue<string>();

        return (
          <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
            {referenceNo}
          </LinkToDetails>
        );
      },
    },
    {
      id: 'idNo',
      header: 'ID Number',
      accessorKey: 'idNo',
    },
    {
      id: 'approvalStatus',
      header: 'Status',
      accessorKey: 'approvalStatus',
      cell: (data) => {
        const approvalStatus = data.cell.getValue<ApprovalStatus>();

        return <StatusField status={formatApprovalStatus(approvalStatus)} label={approvalStatus} />;
      },
    },
    {
      id: 'createdAt',
      header: 'Application Date',
      accessorKey: 'createdAt',
      cell: (data) => {
        const createdAt = data.cell.getValue<Date>();

        return formatDate(createdAt, { formatType: 'dateAndTime' });
      },
    },
  ],
  allowEdit: false,
  allowDelete: false,
  allowDownload: true,
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'Contact details',
        collapsible: 'expand-by-default',
        fields: [
          'id',
          'type',
          'name',
          'accountCreatedDate',
          'approvalStatus',
          'contactNo',
          'createdAt',
          'deletedAt',
          'idNo',
          'idType',
          'referenceNo',
          'updatedAt',
        ],
      }),
    ],
  }),
});
