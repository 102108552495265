import JsonView from 'react-json-view';

import { formatDate, StatusField } from '@amfintech/react-admin-ui';
import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import { AuditTrail, Interface, UserType, ActionStatus } from '~/api';

import { formatAuditStatus } from './admin-audit-resource';
import { resourceNames } from './resource-names';

const { defineFields, defineShowPage, defineCardSection } = createHelpers<AuditTrail>({
  resourceName: resourceNames.auditTrails,
});

export const customerAuditResource = createResource({
  identifier: resourceNames.customerAudit,
  name: resourceNames.auditTrails,
  label: 'Customer Audit Trails',
  fields: defineFields([
    'id',
    'interface',
    'clientIp',
    'serverIp',
    'userType',
    'userId',
    'username',
    'module',
    'action',
    'channel',
    'timestamp',
    'details',
    'status',
    'relatedEntities',
    'relatedIds',
    'sessionId',
    'environment',
    'appVersion',
    'createdAt',
    'updatedAt',
  ]),
  defaultValues: {
    userType: UserType.Customer,
    interface: Interface.App,
  },
  filterControls: {
    action: {
      type: 'select',
      config: {
        label: 'Action',
        options: [
          { label: 'View', value: 'View' },
          { label: 'List', value: 'List' },
          { label: 'Update', value: 'Update' },
          { label: 'Delete', value: 'Delete' },
          { label: 'Create', value: 'Create' },
        ],
      },
      operator: 'eq',
    },
    module: {
      type: 'select',
      config: {
        label: 'Module',
        options: [
          { label: 'Fund', value: 'Fund' },
          { label: 'Account', value: 'Account' },
          { label: 'Transaction', value: 'Transaction' },
          { label: 'Order', value: 'Order' },
          { label: 'Profile', value: 'Profile' },
          { label: 'Announcement', value: 'Announcement' },
          { label: 'Payment', value: 'Payment' },
          { label: 'Statement', value: 'Statement' },
          { label: 'Login', value: 'Login' },
          { label: 'Document', value: 'Document' },
          { label: 'OTP', value: 'OTP' },
          { label: 'Account Portfolio', value: 'AccountPortfolio' },
          { label: 'Account Summary', value: 'AccountSummary' },
          { label: 'Consented Profile', value: 'ConsentedProfile' },
          { label: 'FAQ', value: 'FAQ' },
          { label: 'Support Ticket', value: 'SupportTicket' },
        ],
      },
      operator: 'eq',
    },
    timestamp: {
      type: 'daterange',
      config: {
        label: 'Timestamp',
        disabled: {
          after: new Date(),
        },
      },
    },
    status: {
      type: 'select',
      config: {
        label: 'Status',
        options: Object.values(ActionStatus).map((status) => ({ label: status, value: status })),
      },
      operator: 'eq',
    },
  },
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  allowDownload: true,
  list: {
    downloadConfig: {
      filenamePrefix: 'AuditTrailReport_Customer',
    },
    filters: {
      collapsible: 'expand-by-default',
      permanent: [
        {
          field: 'interface' as any,
          operator: 'eq',
          value: Interface.App,
        },
      ],
    },
  },
  defaultSorter: [{ field: 'timestamp', order: 'desc' }],
  columns: ({ LinkToDetails }) => [
    {
      id: 'timestamp',
      header: 'Timestamps',
      accessorKey: 'timestamp',
      cell: (data) => (
        <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
          {formatDate(data.getValue<string>(), { formatType: 'dateAndTime' })}
        </LinkToDetails>
      ),
    },
    {
      id: 'username',
      header: 'Username',
      accessorKey: 'username',
    },
    {
      id: 'action',
      header: 'Action',
      accessorKey: 'action',
    },
    {
      id: 'module',
      header: 'Module',
      accessorKey: 'module',
    },
    {
      id: 'clientIp',
      header: 'Client IP',
      accessorKey: 'clientIp',
    },
    {
      id: 'serverIp',
      header: 'Server IP',
      accessorKey: 'serverIp',
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      cell: (data) => (
        <StatusField
          status={formatAuditStatus(data.getValue<ActionStatus>())}
          label={data.getValue<ActionStatus>()}
        />
      ),
    },
  ],
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'Audit Details',
        fields: [
          'id',
          'interface',
          'clientIp',
          'serverIp',
          'userType',
          'userId',
          'username',
          'module',
          'action',
          'channel',
          'timestamp',
          'status',
          'sessionId',
          'environment',
          'appVersion',
        ],
      }),
      defineCardSection({
        title: 'Additional Information',
        fields: ['details'],
        collapsible: 'expand-by-default',
        displays: {
          details: {
            label: 'Details',
            render: (data) => (
              <JsonView
                src={data.details}
                displayDataTypes={false}
                displayObjectSize={false}
                enableClipboard={false}
                style={{ padding: '10px' }}
              />
            ),
          },
        },
      }),
    ],
  }),
});
