import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import { FundPolicy } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineShowPage, defineCardSection } = createHelpers<FundPolicy>({
  resourceName: resourceNames.fundPolicy,
});

export const fundPolicyResource = createResource({
  name: resourceNames.fundPolicy,
  label: 'Fund Policy',
  meta: {
    hide: true,
  },
  fields: defineFields([
    'id',
    'minInitInvestment',
    'minSubsequentInvestment',
    'minRedemptionUnit',
    'minHoldingUnit',
    'coolingOffPeriodDays',
    'purchasePeriodDays',
    'redemptionPeriodDays',
    'annualManagementFee',
    'annualTrusteeFee',
    'wholeSaleFlag',
    'salesChargePercent',
  ]),
  defaultValues: {},
  columns: () => [],
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'Details',
        fields: [
          'minInitInvestment',
          'minSubsequentInvestment', 
          'minRedemptionUnit',
          'minHoldingUnit',
          'coolingOffPeriodDays',
          'purchasePeriodDays',
          'redemptionPeriodDays',
          'annualManagementFee',
          'annualTrusteeFee',
          'wholeSaleFlag',
          'salesChargePercent',
        ],
      }),
    ],
  }),
}); 