import { useEffect, useState } from 'react';

import { Button, Card, CardContent } from '@amfintech/react-admin-ui';
import { formatDate } from '@amfintech/react-admin-ui';
import { useOne } from '@refinedev/core';

import { Profile } from '~/api';
import { resourceNames } from '~/resources/resource-names';

export const ProfileRiskProfilePage = (props: { profile?: Profile }) => {
  const { profile } = props;
  const [shouldFetch, setShouldFetch] = useState(false);

  const { data, isLoading } = useOne({
    resource: resourceNames.document,
    id: profile?.riskProfileAttachmentId ?? undefined,
    meta: { fields: ['encodedData'] },
    queryOptions: {
      enabled: shouldFetch,
    },
  });

  const handleDownload = () => {
    setShouldFetch(true);
  };

  useEffect(() => {
    if (data && shouldFetch && profile?.riskProfileAttachmentId) {
      const file = data.data.encodedData;
      const link = document.createElement('a');
      link.href = file;
      link.download = `risk-profile-${profile.riskProfileAttachmentId}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setShouldFetch(false);
    }
  }, [data, shouldFetch, profile?.riskProfileAttachmentId]);

  if (!profile) {
    return null;
  }

  return (
    <Card>
      <CardContent className="p-4">
        <h3 className="text-lg font-semibold mb-4">Risk Profile Information</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <span className="block text-sm font-medium text-gray-700 mb-1">
              Current risk profile
            </span>
            <p>{profile.riskProfile || 'Not available'}</p>
          </div>
          <div>
            <span className="block text-sm font-medium text-gray-700 mb-1">Last updated date</span>
            <p>
              {profile.riskProfileUpdatedAt
                ? formatDate(profile.riskProfileUpdatedAt, { formatType: 'dateAndTime' })
                : 'Not available'}
            </p>
          </div>
          <div>
            <span className="block text-sm font-medium text-gray-700 mb-1">Attachment</span>
            {profile.riskProfileAttachmentId ? (
              <Button variant="solid" onClick={handleDownload}>
                Download Attachment
              </Button>
            ) : (
              <p className="text-sm text-gray-500">No Attachment</p>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
