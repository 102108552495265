import { formatDate, Button } from '@amfintech/react-admin-ui';
import { createHelpers, createResource } from '@amfintech/refine-react-admin';
import { useNavigation } from '@refinedev/core';

import { ModificationEntityType, ModificationType, PublicHoliday } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineShowPage, defineCardSection } = createHelpers<PublicHoliday>({
  resourceName: resourceNames.publicHoliday,
});

export const publicHolidayResource = createResource({
  name: resourceNames.publicHoliday,
  label: 'Public Holiday',
  fields: defineFields(['id', 'name', 'remarks', 'startDate', 'endDate', 'createdAt', 'updatedAt']),
  defaultValues: {},
  allowDownload: true,
  allowEdit: false,
  renderEditPage: true,
  list: {
    filters: {
      collapsible: 'expand-by-default',
    },
    makerChecker: {
      entityTypes: [ModificationEntityType.PublicHoliday],
    },
    downloadConfig: {
      startDate: (value: any) => formatDate(value, { formatType: 'dateOnly' }),
      endDate: (value: any) => formatDate(value, { formatType: 'dateOnly' }),
    },
  },
  filterControls: {
    name: {
      type: 'text',
      config: {
        label: 'Holiday Name',
      },
      operator: 'contains',
    },
    startDate: {
      type: 'date',
      config: {
        label: 'Start Date',
      },
      operator: 'gte',
    },
    endDate: {
      type: 'date',
      config: {
        label: 'End Date',
      },
      operator: 'lte',
    },
  },
  defaultSorter: [
    {
      field: 'startDate',
      order: 'asc',
    },
  ],
  controls: {
    components: {
      name: {
        type: 'text',
        config: {
          label: 'Holiday Name',
          required: true,
        },
      },
      remarks: {
        type: 'textarea',
        config: {
          label: 'Remarks',
        },
      },
      startDate: {
        type: 'date',
        config: {
          label: 'Start Date',
          required: true,
          side: 'right',
        },
      },
      endDate: {
        type: 'date',
        config: {
          label: 'End Date',
          required: true,
          side: 'right',
        },
      },
    },
  },
  columns: ({ LinkToDetails }) => [
    {
      id: 'name',
      header: 'Holiday Name',
      accessorKey: 'name',
      cell: (data) => (
        <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
          {data.getValue<string>()}
        </LinkToDetails>
      ),
    },
    {
      id: 'startDate',
      header: 'Start Date',
      accessorKey: 'startDate',
      cell: (data) => formatDate(data.getValue<string>(), { formatType: 'dateOnly' }),
    },
    {
      id: 'endDate',
      header: 'End Date',
      accessorKey: 'endDate',
      cell: (data) => formatDate(data.getValue<string>(), { formatType: 'dateOnly' }),
    },
    {
      id: 'remarks',
      header: 'Remarks',
      accessorKey: 'remarks',
      cell: (data) => data.getValue<string>(),
    },
  ],
  show: defineShowPage({
    actions: (_, option) => {
      if (option?.modification) {
        return <></>;
      }

      const navigateTo = useNavigation();
      const isExpired = option?.data?.endDate ? new Date(option.data.endDate) < new Date() : false;

      return (
        <>
          <Button
            disabled={!option?.data?.id || isExpired}
            onClick={() => navigateTo.edit(resourceNames.publicHoliday, option!.data!.id)}
            variant="solid"
            size="sm"
            className="min-w-[6rem]"
          >
            Edit
          </Button>
        </>
      );
    },
    sections: [
      defineCardSection({
        title: 'Details',
        fields: ['name', 'startDate', 'endDate', 'createdAt', 'updatedAt', 'remarks'],
        displays: {
          startDate: {
            label: 'Start date',
            render: (data, modifications) => {
              if (!modifications) {
                return formatDate(data.startDate, { format: 'yyyy-MM-dd' });
              }

              return (
                <modifications.ModificationDiff
                  oldValue={
                    modifications.type === ModificationType.Create
                      ? ''
                      : formatDate(modifications.oldValues?.startDate, {
                          format: 'yyyy-MM-dd',
                        })
                  }
                  newValue={formatDate(modifications.newValues?.startDate, {
                    format: 'yyyy-MM-dd',
                  })}
                />
              );
            },
          },
          endDate: {
            label: 'End date',
            render: (data, modifications) => {
              if (!modifications) {
                return formatDate(data.endDate, { format: 'yyyy-MM-dd' });
              }

              return (
                <modifications.ModificationDiff
                  oldValue={
                    modifications.type === ModificationType.Create
                      ? ''
                      : formatDate(modifications.oldValues?.endDate, {
                          format: 'yyyy-MM-dd',
                        })
                  }
                  newValue={formatDate(modifications.newValues?.endDate, { format: 'yyyy-MM-dd' })}
                />
              );
            },
          },
        },
      }),
    ],
  }),
});
